<template>
    <div class="up-down" @click="showPopup(item)">
        <div class="up" v-if="item">
            <div class="moreBox" v-if="item.type" @click.stop="moveLR(3,item.type)">
                <p>查看全部</p>
            </div>
            <img
                v-else
                style="width: 118px;height: 158px;"
                :src="item.path"
                alt="">
        </div>
        <div class="down">
            <div>{{ 100 }} <span class="txt">人在玩</span></div>
        </div>
        <Modal
            :footer-hide="true"
            v-model="modal"
            :closable="false">
            <div class="popup-box">
                <div class="top">
                    <div class="cover">
                        <img :src="popInfo.path" alt="">
                    </div>
                    <div class="txt">
                        <div class="txt1">{{ popInfo.gameName }}</div>
                        <div class="txt2">{{ popInfo.gameFactory }}</div>
                    </div>
                </div>
                <div class="btn-list">
                    <Button class="btn" type="error" @click="modal=!modal">取消</Button>
                    <Button v-if="member" class="btn" type="primary" @click="getGameUrl">
                        <Icon type="ios-play"/>
                        开始
                    </Button>
                    <Button v-else class="btn" type="primary" @click="getGameUrl">
                        登陆
                    </Button>
                </div>
            </div>
        </Modal>
    </div>
</template>
<script>
import members from "@/views/members.vue";

export default {
    name: "",
    props: ['item'],
    data() {
        return {
            modal: false,
            popInfo: {},
        }
    },
    created() {
        // console.log( this.item )
    },
    computed: {
        member() {
            return this.$store.state.member
        }
    },
    methods: {
        moveLR(num,gameCategory){
            this.$parent.moveLR(num,gameCategory)
        },
        showPopup(item) {
            this.modal = !this.modal
            this.popInfo = item
        },
        getGameUrl() {
            if (!localStorage.getItem('TOKEN') || localStorage.getItem('TOKEN') == 'null') {
                this.$router.push({path: '/login'})
                return
            }
            // if(!this.members)
            // symbol   游戏id
            // language ： en   游戏id
            let data = {
                symbol: this.popInfo.gameId,
                language: "en",
                remark: members.remark
            }
            this.$http.post(this.host + '/pp/game/url', data).then(response => {
                const res = response.body
                // window.location.href = res.gameURL + '&remark=USDT'
                this.$router.push({path: '/gamepage', query: {name: this.popInfo.gameName, fcUrl: res.gameURL}})
            })
        }
    }
}
</script>


<style scoped lang="scss">
.up-down {
    padding: 10px 0;
    width: 16.666%;
    display: flex;
    flex-direction: column;

    .up > img {
        border-radius: 6px;
        box-shadow: 0 4px 6px -1px rgba(27, 23, 23, .2), 0 2px 4px -1px rgba(0, 0, 0, .12);
    }

    .up {
        .moreBox {
            width: 118px;
            height: 158px;
            background-image: url("../assets/common/moreGame.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;

            & > p {
                color: #fff;
                font-size: 20px;
                font-weight: 500;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .down {
        color: #fff;
        text-align: left;
        font-size: 12px;
        display: flex;
        justify-content: start;
        align-items: center;
        font-weight: bold;
        padding: 0 10px;

        .green {
            width: 6px;
            height: 6px;
            background-color: #1fff20;
            border-radius: 6px;
            margin-right: 4px;
        }

        .txt {
            color: #bab1d3;
        }
    }
}

.popup-box {
    display: flex;
    flex-direction: column;

    .top {
        display: flex;

        .cover {
            margin-left: 10px;
            width: 30%;

            & > img {
                border-radius: 8px;
                width: 100%;
            }
        }

        .txt {
            color: #fff;
            margin-left: 20px;
            display: flex;
            justify-content: start;
            flex-direction: column;

            .txt1 {
                font-size: 16px;
            }

            .txt2 {
                color: #ccc;
            }
        }
    }

    .btn-list {
        display: flex;
        justify-content: space-around;

        .btn {
            height: 40px;
            width: 48%;
        }
    }
}

/deep/ .ivu-modal-content {
    margin-top: 160px;
    border-radius: 10px;
    background-color: #1A2C38;
}

/deep/ .ivu-modal-mask{
    background-color: rgba(0, 0, 0, .8)!important;
}
</style>
